export default async ({ app }) => {
  app.$auth.onRedirect((to, _from) => {
    return app.localePath(to)
  })

  if (!app.$auth || !app.$auth.loggedIn) {
    return
  }

  try {
    await app.store.dispatch('multiCurrency/fetch_currencies')
    await app.store.dispatch('userSettings/fetch_settings')
  } catch (error) {
    console.error({ error })
  }
}
